.failed {
  color: #ff5d5d;
}
.tdStrongBorder {
  border-left: 4px solid #AFC2DB;
}

.plus {
  color: #396eff;
}

.minus {
  color: #ff5d5d;
}

.categoryWrapper {
  margin-right: 8px;
}

.memo {
  display: flex;
}


.isNotCurrentMonth {
  background-color: #ffecec;
  color: #ff5d5d;
}



.odd {
  background-color: #f7f8fb;
}
.even {
  background-color: #fff;
}
