.excelDownButton {
  width: 126px;
  height: 40px;
  box-sizing: border-box;
  position: relative;
  padding: 8px 13px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 1.67;
  color: #396eff;
  box-shadow: 0 2px 4px 0 rgba(39, 46, 64, 0.1);
  border: solid 1px #e9edf4;
  cursor: pointer;
}
