th {
  border: 1px solid #608bff;
  background-color: #396eff;
}

td {
  border: 1px solid #afc2db;
}

.table {
  width: 100%;
  margin-top: 20px;
}

.thStrongBorder {
  border-left: 4px solid #608bff;
}
