.header {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(39, 46, 64, 0.03);
  padding: 0 20px;
  .right {
    display: flex;
    align-items: center;
    .button {
      position: relative;
      height: 46px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding: 10px 12px;
      font-size: 12px;
      line-height: 1.67;
      color: #10131a;
      box-shadow: 0 2px 4px 0 rgba(39, 46, 64, 0.1);
      border: solid 1px #e9edf4;
      background-color: #ffffff;
      cursor: pointer;
      margin-right: 4px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
