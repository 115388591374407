.dialog {
  position: relative;
  .dialogInput {
    position: relative;
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 120px;
    padding: 0 8px;
    border: solid 1px #e9edf4;
    height: 24px;
    min-height: 24px;
    background-color: white;
    cursor: pointer;
    .arrow {
      transition: transform 0.3s ease-out;
    }
    .isOpen {
      transform: rotate(180deg);
    }
  }
  .options {
    z-index: 1;
    width: 100%;
    background-color: white;
    position: absolute;
    top: 28px;
    border: 1px solid #e9edf4;
    padding: 4px 0;
  }
  .optionMenu {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 8px;
    height: 24px;
    text-align: left;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  .default {
    color: #afc2db;
  }
}
