@import "../../styles/mixin";

.inconsistency {
  margin-top: 60px;
  .title {
    @include font("fsb");
    font-size: 20px;
    line-height: 1.6;
    color: #272e40;
    margin-bottom: 16px;
  }
}
