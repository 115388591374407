td {
  border: 1px solid #afc2db;
}

.odd {
  background-color: #f7f8fb;
}
.even {
  background-color: #fff;
}
