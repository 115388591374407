.header {
  padding-bottom: 19.5px;
  border-bottom: 1px solid #ccd8e8;
  .content {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 24px;
    .row {
      display: flex;
      align-items: center;
    }
    .plus {
      color: #396eff;
    }
    .minus {
      color: #ff5d5d;
    }
  }
}
