@import '../../../styles/variables';

.toastMessageItem {
  position: fixed;
  right: 25px;
  bottom: 25px;
  z-index: 999;
  background: #ffffff;
  box-shadow: 0 15px 45px rgba(0, 0, 0, 0.2);
  padding: 10px;
  font-size: 15px;
  border-radius: 2px;

  &.isSuccess {
    color: #fff;
    background: $green;
  }

  &.isWarning {
    color: #fff;
    background: $orange;
  }

  &.isError {
    color: #fff;
    background: $red;
  }
}
