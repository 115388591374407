.textareaField {
  display: flex;
  align-items: center;
  width: 100%;

  .label {
    color: #98a3ad;
    font-size: 12px;
    margin-right: 10px;
  }

  .input {
    width: 100%;
    border: solid 1px #e9edf4;
    -webkit-appearance: none;
    padding: 2px 8px;
    height: 24px;
    font-size: 12px;
    line-height: 1.5;
    box-sizing: border-box;
    transition: box-shadow 0.15s ease-in-out;
    background: #ffffff;

    &:hover,
    &:focus {
      border: 1px solid #396eff;
    }
  }
}
