@import "../../styles/variables";

.inputField {
  display: flex;
  align-items: center;
  min-width: 100px;
  width: 100%;
  box-sizing: border-box;

  .label {
    color: #98a3ad;
    font-size: 12px;
    margin-right: 10px;
    box-sizing: border-box;
  }

  .input {
    border: solid 1px #e9edf4;
    -webkit-appearance: none;
    padding: 8px 10px;
    color: #272e40;
    height: 24px;
    font-size: 12px;
    line-height: 1.5;
    box-sizing: border-box;
    width: 100%;
    &:hover,
    &:focus {
      border: 1px solid #396eff;
    }
  }
}
