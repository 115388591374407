@import "../../styles/mixin";

.delay {
  margin-top: 60px;
  .title {
    @include font("fsb");
    font-size: 20px;
    line-height: 1.6;
    color: #272e40;
    margin-bottom: 16px;
  }
  .delayTable {
    th {
      background-color: #869ab7;
      border-color: #afc2db;
    }
  }
}
