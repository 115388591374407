@import '../../styles/mixin';

.monthPicker {
  height: 60px;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  padding: 18px 12px;
  background-color: white;
  border: 1px solid #e9edf4;
  box-shadow: 0 2px 4px 0 rgba(39, 46, 64, 0.1);
  .month {
    margin-right: 18px;
    font-size: 16px;
    line-height: 1.5;
    color: #272e40;
    @include font('fsb');
  }
  .calendarIcon {
    margin-right: 8px;
  }

  .arrowIconWrapper {
    height: 24px;
    position: relative;
    cursor: pointer;
  }
  .beforeIconWrapper {
    margin-right: 8px;
  }
}
