@mixin paperShadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}


@mixin font($type) {
  @if($type == "fsb") {
    font-family: 'SDPRNDGothicNeoaUni-fSb', sans-serif !important;
  }

  @else if($type == "drg") {
    font-family: 'SDPRNDGothicNeoaUni-dRg', sans-serif !important;
  }

  @else if($type == "clt") {
    font-family: 'SDPRNDGothicNeoaUni-cLt', sans-serif !important;
  }
}
