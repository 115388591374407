@import "./styles/mixin";

@font-face {
  font-family: "SDPRNDGothicNeoaUni-fSb";
  font-weight: normal;
  font-style: normal;
  src: url("./font/SDPRNDGothicNeoaUni-fSb.woff");
}

@font-face {
  font-family: "SDPRNDGothicNeoaUni-dRg";
  font-weight: normal;
  font-style: normal;
  src: url("./font/SDPRNDGothicNeoaUni-dRg.woff");
}

@font-face {
  font-family: "SDPRNDGothicNeoaUni-cLt";
  font-weight: normal;
  font-style: normal;
  src: url("./font/SDPRNDGothicNeoaUni-cLt.woff");
}

body {
  font-size: 14px;
  margin: 0;
  font-family: "SDPRNDGothicNeoaUni-dRg", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(248, 249, 250);
  color: #272e40;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 4px;
}

th,
td {
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
  font-size: 12px;
  line-height: 1.67;
}

th {
  @include font("fsb");
  color: white;
}

a {
  color: #396eff;
  text-decoration: none;
  outline: none;
}

a:hover,
a:active {
  text-decoration: none;
  color: #396eff;
}
